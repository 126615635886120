import React from "react"
import styled from "styled-components"

const TitleSecondary = styled.h2`
  font-family: var(--ff-secondary);
  font-style: normal;
  text-transform: uppercase;

  font-weight: 700;
  font-size: 1.25em;
  line-height: 20px;
  letter-spacing: 0.05em;

  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  @media (min-width: 40em) {
    font-size: 1.5em;
    line-height: 39px;
  }
`

const TitleTertiary = styled.h3`
  font-weight: 500;
  font-size: 1.125em;
  line-height: 18px;
  letter-spacing: 0.0015em;
  text-align: left;

  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  @media (min-width: 40em) {
    font-size: 1.25em;
    line-height: 20px;
  }
`

const Paragraph = styled.p`
  font-weight: 400;
  font-size: 0.875em;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

  @media (min-width: 40em) {
    font-size: 1em;
  }
`

const PrivacyNoticeList = styled.ul`
  font-size: 0.875em;
  list-style-position: outside;
  margin-left: 0;
  padding: 0.5rem 1.25rem;
  li {
    color: #000000;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    margin-left: 0;
    margin-bottom: 0.25rem;
  }

  @media (min-width: 40em) {
    font-size: 1em;
  }
`

const TextContentContainer = styled.section`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 15rem;

  width: min(1000px, 100% - 2.5rem);
  margin-inline: auto;

  @media (min-width: 40em) {
    margin-bottom: 10rem;
    margin-top: 5.5rem;
    padding: 3rem;
    box-shadow: rgb(122, 121, 121, 0.2) 0px 0px 0px 1px,
      0px 4px 10px rgba(0, 0, 0, 0.15);
    background: white;
  }

  a {
    text-decoration: none;
    color: var(--clr-text-blue);

    &:hover {
      text-decoration: underline;
    }
  }
`

const PrivateNoticeContent = () => (
  <TextContentContainer>
    <TitleSecondary id="light-red" className="light-red">
      DATA CONTROLLER
    </TitleSecondary>
    <Paragraph>
      Rebase Consulting Oy (business identity code 3125035-2)
      <br />
      Lönnrotinkatu 5<br />
      00120 Helsinki
      <br />
      <a href="mailto:rebase@rebase.fi">rebase@rebase.fi</a>
    </Paragraph>
    <TitleSecondary className="light-red">
      CONTACT US IN PRIVACY MATTERS
    </TitleSecondary>
    <Paragraph>
      You may send your questions and requests regarding privacy to
      <br />
      <a href="mailto:privacy@rebase.fi">privacy@rebase.fi</a>
    </Paragraph>
    <TitleSecondary className="light-red">
      GENERAL DESCRIPTION OF OUR DATA PROTECTION PRACTICES
    </TitleSecondary>
    <Paragraph>
      Rebase processes personal data in accordance with applicable legislation,
      including the General Data Protection Regulation (Regulation (EU)
      2016/679, “GDPR”), and this privacy notice.{" "}
    </Paragraph>
    <Paragraph>
      We take into account the requirements set forth in the data protection
      legislation in all our business activities and we expect and require the
      same from our subcontractors and other business partners.
    </Paragraph>
    <Paragraph>
      {" "}
      We provide orientation and training for our employees regarding the
      requirements and guidelines for privacy so that they know how to perform
      accordingly.
    </Paragraph>
    <TitleSecondary className="light-red">
      WHAT PERSONAL DATA WE COLLECT AND FOR WHAT PURPOSE?
    </TitleSecondary>
    <Paragraph>
      We collect the following personal data solely for predefined purposes
      described below.
    </Paragraph>
    <TitleTertiary>Job applicants</TitleTertiary>
    <Paragraph>
      We process the following personal data during the job application and
      recruitment process:
    </Paragraph>
    <PrivacyNoticeList>
      <li>First name and surname</li>
      <li>Email address</li>
    </PrivacyNoticeList>
    <Paragraph>
      In addition to the above, we will process the following data to the extent
      you provide us with such data during the recruitment process:
    </Paragraph>
    <PrivacyNoticeList>
      <li>Telephone number</li>
      <li>Home address</li>
      <li>Current employer</li>
      <li>Education and work experience</li>
      <li>Skills and know-how</li>
      <li>
        Publicly available information on LinkedIn, Github and Twitter profiles
      </li>
      <li>
        Information shared by you through CV and portfolio and other information
        and annexes you choose to provide us
      </li>
    </PrivacyNoticeList>
    <Paragraph>
      Additionally, to the appropriate extent, we will save the following data
      during the recruitment process:
    </Paragraph>
    <PrivacyNoticeList>
      <li>
        Assessment on your suitability to the position you have applied for or,
        with your consent, to other open positions in our company
      </li>
      <li>
        Correspondence between you and us as well as data collected during the
        job interview
      </li>
      <li>Possible information provided by your referees</li>
      <li>
        Information on the progress of the recruitment and outcome thereof as
        well as implementation of the position possible related to the
        recruitment
      </li>
      <li>
        Our possible feedback to you so that you can improve even more at work
        after the recruitment process
      </li>
    </PrivacyNoticeList>
    <Paragraph>
      Collected data is used in order for us to process your job application
      (including, to carry out the recruitment process, to review your job
      application, to carry out the job interview and selection of the
      recruitment candidate) and keep in contact with you during the recruitment
      process, as well as support the possible orientation and beginning of the
      employment relationship.
    </Paragraph>
    <Paragraph>
      The personal data collected consists primarily of the information that you
      choose to provide us. With your consent, information may be received from
      other sources as well, for example through references given to us by you.
    </Paragraph>
    <Paragraph>
      We process your data for a period of one year as of the receipt of your
      application during which we may, upon your consent, evaluate your
      suitability and be in contact with you in relation to other open job
      vacancies. At the end of the one-year-period, we may be in contact with
      you and request your consent to extend the processing period.
    </Paragraph>
    <Paragraph>
      We remove all data related to you within three months, at the latest, as
      of the end of the afore-mentioned one-year-period and/or extended period.
      When removing the data, we delete all information furnished by you and
      collected by us in a manner that solely general and unidentifiable
      information related to application (e.g. the respective vacancy and its
      location) will remain for statistical purposes.
    </Paragraph>
    <TitleTertiary>Customers and business partners</TitleTertiary>
    <Paragraph>
      If you are our customer or business partner, we may process the following
      personal data:
    </Paragraph>
    <PrivacyNoticeList>
      <li>First name and surname</li>
      <li>Organisation information including contact details</li>
      <li>Role and contact details (email and telephone number)</li>
      <li>
        Other information provided to us (e.g. customer feedback, registration
        to an event and subscription to newsletter)
      </li>
      <li>IP address</li>
    </PrivacyNoticeList>
    <Paragraph>
      Collected data is used in order for us to manage customer and partnership
      relations as well as marketing, implementing and developing services and
      events of Rebase.
    </Paragraph>
    <Paragraph>
      The personal data is collected primarily within the context of governance
      and implementation of customer and partner relationship. Such data include
      also the information that the person chooses to provide us (e.g. when you
      sign up for an event organised by us or subscribe to our newsletter or
      when you visit our website).
    </Paragraph>
    <TitleTertiary>Other contacts</TitleTertiary>
    <Paragraph>
      If you are in contact with us, for example, to ask about our services, to
      sign up for an event organised by us, or to order our newsletter, we may
      process the following personal data to the extent we have obtained such
      data from you:
    </Paragraph>
    <PrivacyNoticeList>
      <li>First name and surname</li>
      <li>Email address</li>
      <li>Telephone number</li>
      <li>Organisation name and contact details</li>
      <li>Country</li>
      <li>Role and contact details</li>
      <li>IP address</li>
      <li>
        Other information you choose to provide us (e.g. web address of LinkedIn
        profile)
      </li>
    </PrivacyNoticeList>
    <Paragraph>
      Collected data is used in order for us to process your contact or request
      as well as keep in contact with you.
    </Paragraph>
    <Paragraph>
      {" "}
      The personal data collected consists primarily of the information that you
      choose to provide us. In addition, data may consist information about your
      use of our website through cookies (read more about our cookie policy in
      “Cookies” below).
    </Paragraph>
    <TitleSecondary className="light-red">
      WHAT ARE THE GROUNDS FOR PROCESSING PERSONAL DATA?
    </TitleSecondary>
    <Paragraph>
      When we process personal data for the purposes described above, our
      processing is primarily based on a legitimate interest of Rebase. Our
      legitimate interest may relate to, for example, ensuring and improving our
      data security or premises and network, prevention and investigation of
      suspected misappropriation as well as protection of Rebase’s property. In
      some cases we also may have a legal obligation to process your personal
      data.{" "}
    </Paragraph>
    <Paragraph>
      In addition to or instead of the above, the processing may be based on
      performance of a contract between you and Rebase in which case we process
      the personal data in order to perform the obligations and carry out the
      purposes set forth in the respective contract.{" "}
    </Paragraph>
    <Paragraph>
      In addition to or instead of the above, in some cases the processing may
      be based on a consent given to us by you (e.g. when you give us the
      consent to store the information you have given us during the recruitment
      process for future job opportunities).
    </Paragraph>
    <TitleSecondary className="light-red">
      FOR HOW LONG DO WE PROCESS PERSONAL DATA?
    </TitleSecondary>
    <Paragraph>
      We process personal data for only as long as required or entitled by
      applicable legislation.
    </Paragraph>
    <Paragraph>
      Therefore, it depends on the data type and purpose of use as to how long
      we store the personal data. Rebase stores the personal data at least as
      long as necessary to fulfil the purpose of processing, e.g. to meet the
      contractual obligation.
    </Paragraph>
    <Paragraph>
      The storage time will be determined on the following criteria:
    </Paragraph>
    <PrivacyNoticeList>
      <li>
        Personal data will be stored as long as Rebase’s legitimate interest
        related to them exists. The existence of a legitimate interest will be
        determined, for example, on the basis of the mutual correspondence
        between Rebase and a data subject. The storage time of the personal data
        of the representatives of the customers and business partners will be
        determined eventually on the basis of the contract between Rebase and
        the respective business entity.{" "}
      </li>

      <li>
        In the event the processing of personal data is based on a consent of
        data subject, the personal data will be removed when the data subject
        cancels the consent.
      </li>

      <li>
        The storage time may also be defined by the applicable law. For example,
        according to Accounting Act, accounting documentation must be stored for
        a period of six years.
      </li>
    </PrivacyNoticeList>
    <Paragraph>
      We review the necessity of the data stored on a regular basis and erase
      any data when it no longer needed for the purposes mentioned above in this
      privacy notice.
    </Paragraph>
    <TitleSecondary className="light-red">YOUR RIGHTS</TitleSecondary>
    <Paragraph>
      Rebase acts diligently to ensure that you are able to exercise your rights
      regarding the processing of your personal data. You have a
    </Paragraph>
    <PrivacyNoticeList>
      <li>
        right to access to your personal data. You may request Rebase to confirm
        whether we process your personal data, and a copy of your personal data
        possibly processed by us, by sending us such request at the email
        address mentioned above in this privacy notice;
      </li>
      <li>
        right to rectification and/or erasure of the data. You may request us to
        correct your personal data that is erroneous or inaccurate, and further,
        you may request us to erase your personal data. You can exercise these
        rights by sending us such request at the email address mentioned above
        in this privacy notice;
      </li>
      <li>
        right to restrict the processing of your personal data. In certain
        situations, you may request us to restrict the processing of your
        personal data by sending us such request at the email address mentioned
        above in this privacy notice;
      </li>
      <li>
        right to object to the processing of your personal data. You may object
        to certain processing of your personal data if the processing is based
        on a legitimate interest of Rebase (e.g. use of your personal data for
        marketing purposes) by sending us such request at the email address
        mentioned above in this privacy notice.
      </li>
      <li>
        right to data portability. You may request us to provide you with your
        data in a structured, commonly used and machine-readable format so that
        you can transmit your data to another controller by sending us such
        request at the email address mentioned above in this privacy notice.
        This right concerns personal data in an electronic format, the
        processing of which is based on either consent given by you or
        performance of a contract;
      </li>
      <li>
        right to withdraw your consent. Where the processing is based on a
        consent given by you, you may withdraw such consent at any time by
        sending us such request at the email address mentioned above in this
        privacy notice; and
      </li>
      <li>
        right to file a complaint with a supervisory authority. If you wish to
        file a complaint as to how we process your personal data, according to
        the GDPR, you have a right to file a complaint with the supervisory
        authority in the member state where you live or work or where an alleged
        breach of the GDPR has occurred. In Finland, the Data Protection
        Ombudsman acts as the respective supervisory authority
        (www.tietosuoja.fi).
      </li>
    </PrivacyNoticeList>
    <TitleSecondary className="light-red">COOKIE POLICY</TitleSecondary>
    <Paragraph>
      A cookie is a small text file that the browser saves on the terminal
      device when the user uses the website.
    </Paragraph>
    <Paragraph>
      Rebase uses cookies on its website to facilitate the use of the website.
      The user cannot be identified based only on cookies. The cookies and the
      data collected with them is used in order to monitor usage, analyse the
      usability and use of the website, and to develop the website.
    </Paragraph>
    <Paragraph>We use following cookies on our website:</Paragraph>
    <PrivacyNoticeList>
      <li>
        Google Analytics. We use Google Analytics for monitoring our website. In
        addition, Google saves cookies on our website under which Google
        monitors the use of its customers’ website(s). Read more about Google
        Analytics here and Google’s services in general here. You can prohibit
        the use of cookies on your computer by changing your browser settings.
        Please note that if you do not accept the use of the cookies, all parts
        of the pages may not be visible on your computer.
      </li>
      <li>
        LinkedIn. We use LinkedIn for user monitoring on our website. Read more
        about LinkedIn cookies{" "}
        <a
          href="https://www.linkedin.com/legal/cookie-policy"
          target="_blank"
          rel="noreferrer"
        >
          here.
        </a>
      </li>
    </PrivacyNoticeList>
    <TitleSecondary className="light-red">
      PROTECTION OF PERSONAL DATA
    </TitleSecondary>
    <Paragraph>
      Information security and protection of personal data has been organised in
      accordance with the industry best practices at Rebase. Personal data has
      been protected from unauthorised access and processing as well as against
      unlawful and accidental destruction, loss and corruption. In addition,
      Rebase constantly improves practices in order to protect data. Information
      security and related risk management are based on the governance system
      and related information security policy. The information security policy
      is in compliance with the ISO27002 (2013) structure, as applicable.
    </Paragraph>
    <Paragraph>
      Responsibility for the maintenance of information security operations and
      processing of any deviations lies with an information security group that
      is being managed by the leader of that information security group.
    </Paragraph>
    <Paragraph>
      We process personal data as confidential information and all our users are
      committed to confidentiality and comply with our guidelines on data
      protection and information security. Access management of confidential
      information has been organised through a centralised or system-specific
      user directory. Only named persons may grant an access and user rights to
      a data system, provided that the person requesting for an access has been
      identified and that the person’s need for access to confidential
      information has been verified. Rebase assesses and reviews access and user
      rights on a regular basis.
    </Paragraph>
    <Paragraph>
      If we outsource processing of personal data to third parties, we make
      agreements with such third parties as required by the data protection
      legislation in order for us to ensure that the processing of personal data
      complies with this privacy note as well as applicable laws, regulations
      and orders issued by relevant authorities.
    </Paragraph>
    <TitleSecondary className="light-red">
      TRANSFER OF DATA OUTSIDE THE EU OR EEA
    </TitleSecondary>
    <Paragraph>
      Personal data on our recruitment candidates is processed by our US-based
      IT service provider Lever Inc., with whom we have entered into a data
      processing agreement in accordance with Article 28 of the GDPR. Also, data
      concerning our customers, business partners and other contacts may be
      processed by service providers outside the EEA in which case we have
      entered into appropriate agreements with such service providers in order
      to ensure that data transferred will be processed in accordance with the
      law. Any data transfers related to such agreements are based on the
      decision of the European Commission of 5 February 2010 and related
      standard contractual clauses and/or Privacy Shield arrangement entered
      into by and between the EU and the United States.
    </Paragraph>
    <Paragraph>
      Always if and when personal data is transferred outside of the EU or EEA,
      we ensure by contracts or other legal instruments that the transfer occurs
      in accordance with the applicable data protection legislation and that
      there is an adequate level of data protection.
    </Paragraph>
    <Paragraph>
      Rebase is in the member state of the EU, in Finland. If the purpose of the
      processing of personal data requires transfer of data between Rebase and
      its parent company, we may share personal data within the Gofore group in
      which case, likewise, we will ensure that the processing of personal data
      complies with the applicable data protection legislation and this privacy
      notice.
    </Paragraph>
    <TitleSecondary className="light-red">
      UPDATES TO THIS PRIVACY NOTICE
    </TitleSecondary>
    <Paragraph>
      We update this privacy notice when there are changes in the processing of
      personal data or in the applicable laws of which we need to inform you. In
      addition, we may update this privacy notice when we develop our website,
      services or business activities. The most recent version of the privacy
      notice is available at this website.
    </Paragraph>
  </TextContentContainer>
)

export default PrivateNoticeContent
