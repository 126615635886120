import React from "react"
import Layout from "../components/common/Layout"
import PrivateNoticeContent from "../components/privacy-notice/PrivateNoticePageContent"
import Meta from "../components/common/Meta"
import PageIntro from "../components/common/intro/PageIntro"
import Main from "../components/common/Main"

export const Head = () => (
  <Meta title="Rebase - Privacy notice" description="Privacy notice" />
)

const PrivacyNotice = () => {
  const title = [["Rebase  –", "Privacy notice"]]
  return (
    <Layout>
      <Main>
        <PageIntro
          title={title}
          subheading="Privacy and responsible business are very important for us. In Rebase
          Consulting Ltd (hereinafter “Rebase”, “we” or “us”) 
          privacy and data protection are seen as part of all business activities.
          We protect and safeguard personal data in accordance with the laws and
          regulations. This notice describes the principles and procedures with
          which we comply when we collect and process personal data."
        />
        <PrivateNoticeContent />
      </Main>
    </Layout>
  )
}

export default PrivacyNotice
